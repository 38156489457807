import React from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import Fade from "react-reveal/Fade"
import Flip from "react-reveal/Flip"
import Slide from "react-reveal/Slide"
import { withTrans } from "../../i18n/withTrans"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"
/** COMPONENTS */
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import useStyles from "../../styles/startHereStyles"
// import './start-here.scss'
/** MATERIAL UI */
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Paper, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...{
      prefix: "Rp ",
      suffix: "",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ".",
      allowDecimal: false,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
    maskOptions,
  })

  return <MaskedInput mask={currencyMask} {...inputProps} />
}

CurrencyInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
}

function StartHerePage() {
  const { t, i18n } = useTranslation()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const classes = useStyles(isMobile)

  const [profit, setProfit] = React.useState("")
  const [nominal, setNominal] = React.useState("")

  const calculateProfit = () => {
    if (nominal) {
      let nominalInt = parseInt(nominal.replace(/[^0-9-]+/g, ""))
      setProfit(rupiah(nominalInt + (nominalInt * 4.2) / 100))
    }
  }

  const rupiah = number => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number)
  }

  return (
    <Layout>
      <Seo id={"start-here"} title={t(`menus.start-here`)} />
      {/* SECTION BANNER TOP */}
      {/* <Container style={{paddingLeft: '0px', paddingRight: '0px'}}> */}
      <div className={classes.headerContent}>
        {isDesktop ? (
          i18n.language === "en" ? (
            <StaticImage
              src="../../assets/images/Start_Here_Banner_Desktop_English.png"
              className={classes.bannerImage}
              alt="banner-home"
            />
          ) : (
            <StaticImage
              src="../../assets/images/Start_Here_Banner_Desktop_Indo.png"
              className={classes.bannerImage}
              alt="banner-home"
            />
          )
        ) : i18n.language === "en" ? (
          <StaticImage
            src="../../assets/images/Start_Here_Banner_Phone_English.png"
            className={classes.bannerImage}
            alt="banner-home"
          />
        ) : (
          <StaticImage
            src="../../assets/images/Start_Here_Banner_Phone_Indo.png"
            className={classes.bannerImage}
            alt="banner-home"
          />
        )}
        <Fade bottom>
          <div className={classes.headerContentText}>
            {/* <div className={classes.headerContentTextTitle}>{t(`start-here.title-banner`)}</div>
              <div className={classes.headerContentTextContent}>{t(`start-here.content-banner`)}</div> */}
          </div>
          <div className={classes.headerContentTextLeft}></div>
          <Container
            className={clsx(classes.container, classes.animationHeader)}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              {/* <Grid item md={6} xs={6}></Grid>
                <Grid item md={6} xs={6}>
                
                </Grid> */}
            </Grid>
          </Container>
        </Fade>
      </div>
      {/* </Container> */}

      {/* SECTION WHY CHOOSE ASETKU */}
      <Container>
        <div className={classes.whyChoose}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
          >
            <StaticImage
              src={"../../assets/images/decoration/why-choose-circle.svg"}
              className={classes.whyChooseCircle}
              alt="circle"
            />
            {/* SECTION 1 */}
            <Grid item md={12} xs={12} className={classes.whyChooseTitle}>
              {t(`start-here.why-title`)}
            </Grid>

            {/* SECTION 2 */}
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={1000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u21_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt="normal_u21_div"
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-1-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-1-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={1000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u16_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt="normal_u16_div"
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-2-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-2-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={2500}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u9_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt="normal_u9_div"
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-3-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-3-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={2500}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u12_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt="normal_u12_div"
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-4-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-4-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={4000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u28_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt="normal_u28_div"
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-5-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-5-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={4000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u23_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt="normal_u23_div"
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-6-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-6-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      {/* SECTION YELLOW */}
      {isMobile ? (
        <>
          <div className={classes.black}>
            <Container>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item lg={6} md={12} style={{ paddingTop: 20 }}>
                  <div className={classes.yellowRightSideMobile}>
                    <Slide left>
                      <div className={classes.yellowLeftSideTItle}>
                        {t(`start-here.yellow-left-title`)}
                      </div>
                    </Slide>
                    <Slide delay={500}>
                      <div className={classes.yellowLeftSideContent}>
                        {t(`start-here.yellow-left-content`)}
                      </div>
                    </Slide>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.whiteUnderline}></div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item lg={6} md={12} style={{ paddingTop: 20 }}>
                  <div
                    className={
                      isMobile
                        ? classes.yellowRightSideMobile
                        : classes.yellowRightSideDesktop
                    }
                  >
                    <Slide right>
                      <div className={classes.yellowRightSideTItle}>
                        {t(`start-here.yellow-right-title`)}
                      </div>
                    </Slide>
                    <Slide right delay={500}>
                      <div className={classes.yellowRightSideContent}>
                        {t(`start-here.yellow-right-content`)}
                      </div>
                    </Slide>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      ) : (
        <div className={classes.black}>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid
                item
                lg={6}
                md={12}
                style={isMobile ? { paddingBottom: 40 } : {}}
              >
                <div
                  className={
                    isMobile
                      ? classes.yellowLeftSideMobile
                      : classes.yellowLeftSideDesktop
                  }
                >
                  <Slide left>
                    <div
                      className={classes.yellowLeftSideTItle}
                      style={{ textAlign: "left" }}
                    >
                      {t(`start-here.yellow-left-title`)}
                    </div>
                  </Slide>
                  <Slide left delay={500}>
                    <div
                      className={classes.yellowLeftSideContent}
                      style={{ textAlign: "left" }}
                    >
                      {t(`start-here.yellow-left-content`)}
                    </div>
                  </Slide>
                </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                style={isMobile ? { paddingTop: 40 } : {}}
              >
                <div
                  className={
                    isMobile
                      ? classes.yellowRightSideMobile
                      : classes.yellowRightSideDesktop
                  }
                >
                  <Slide right>
                    <div
                      className={classes.yellowRightSideTItle}
                      style={{ textAlign: "right" }}
                    >
                      {t(`start-here.yellow-right-title`)}
                    </div>
                  </Slide>
                  <Slide right delay={500}>
                    <div
                      className={classes.yellowRightSideContent}
                      style={{ textAlign: "right" }}
                    >
                      {t(`start-here.yellow-right-content`)}
                    </div>
                  </Slide>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}

      {/* SECTION LINK APPS */}
      <div className={classes.linkApps}>
        <Container>
          <div className={classes.linkAppsCenter}>
            <a
              href="https://play.google.com/store/apps/details?id=com.silvrr.finance"
              target="_blank"
              className={classes.linkAppsButton}
              rel="noreferrer"
            >
              <div className={classes.linkAppsButtonImg}>
                <StaticImage
                  src={"../../assets/images/icons/normal_u87_div.png"}
                  width={28}
                  alt="normal_u87_div"
                />
              </div>
              <div className={classes.linkAppsButtonDesc}>
                {t(`general.download-on`)} <br />
                <b>
                  <font style={{ fontSize: 14 }}>GOOGLE PLAY</font>
                </b>
              </div>
            </a>

            <a
              href="https://apps.apple.com/id/app/asetku/id1300452602"
              target="_blank"
              className={classes.linkAppsButton}
              rel="noreferrer"
            >
              <div className={classes.linkAppsButtonImg}>
                <StaticImage
                  src={"../../assets/images/icons/normal_u92_div.png"}
                  width={28}
                  alt="normal_u92_div"
                />
              </div>
              <div className={classes.linkAppsButtonDesc}>
                {t(`general.download-on`)} <br />
                <b>
                  <font style={{ fontSize: 14 }}>APP STORE</font>
                </b>
              </div>
            </a>
          </div>
        </Container>
      </div>

      {/* SECTION TOTAL COST */}
      <div className={classes.calculatorContainer}>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Flip bottom>
                <h3 className={classes.fundingCalculation}>
                  {t(`start-here.funding-calculation`)}
                </h3>
              </Flip>

              {/* INPUT NOMINAL */}
              <Grid item md={12} xs={12}>
                <Grid className={classes.calculateTextLabel}>
                  {t(`start-here.calculate-input-nominal`)}
                </Grid>
                <Grid className={classes.calculateInputBox}>
                  <Paper component="form" className={classes.paperInputNominal}>
                    <CurrencyInput
                      className={classes.inputNominal}
                      placeholder={t(`start-here.calculator-input-placeholder`)}
                      type="text"
                      onChange={event => setNominal(event.target.value)}
                    />
                  </Paper>
                </Grid>
              </Grid>

              {/* CHOOSE PRODUCT */}
              <Grid item md={12} xs={12}>
                <Grid className={classes.calculateTextLabel}>
                  {t(`start-here.calculate-choose-product`)}
                </Grid>
                <Grid className={classes.calculateInputBox}>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without Label" }}
                    className={classes.chooseProduct}
                  >
                    <MenuItem>Pendanaan Super 30 Hari</MenuItem>
                  </Select>
                  <FormHelperText className={classes.chooseProductHelperText}>
                    {t("start-here.choose-product-helper")}
                  </FormHelperText>
                </Grid>
              </Grid>

              {/* TOTAL PROFIT */}
              <Grid item md={12} xs={12}>
                <Grid className={classes.calculateTextLabel}>
                  {t(`start-here.calculate-total-profit-label`)}
                </Grid>
                <Grid className={classes.totalProfit}>{profit}</Grid>
              </Grid>

              <Grid item md={12} xs={12}>
                <button
                  // variant="contained"
                  className={classes.calculateButton}
                  onClick={calculateProfit}
                >
                  {t(`start-here.calculate-button`)}
                </button>
              </Grid>
              {/* <div className={classes.imageCalculatorWrapper}> */}
              {/* <Slide right> */}
              <StaticImage
                src={"../../assets/images/picture/normal_calculator_u42.png"}
                className={classes.imageCalclulator}
                alt="calculator"
              />
              {/* </Slide> */}
              {/* </div> */}
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.creditSectionWrapper}>
        {isDesktop && (
          <StaticImage
            src="../../assets/images/icons/normal_u68.png"
            width={68}
            alt="normal_u68"
          />
        )}
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
          >
            <Grid item lg={6} md={5} xs={12}>
              <Flip bottom>
                <h3 className={classes.creditTitle}>
                  {t("start-here.credit-title-1")}
                </h3>
              </Flip>
              <div className={classes.creditImage}>
                <Fade>
                  {i18n.language === "en" ? (
                    <StaticImage
                      src="../../assets/images/picture/Flow_Credit_Insurance_English.jpg"
                      alt="credit-flow"
                    />
                  ) : (
                    <StaticImage
                      src="../../assets/images/picture/Flow_Credit_Insurance_Indo.jpg"
                      alt="credit-flow"
                    />
                  )}
                </Fade>
                <Flip bottom>
                  <p className={classes.creditText}>
                    {t("start-here.credit-text-1")}
                  </p>
                </Flip>
              </div>
            </Grid>
            {!isDesktop && (
              <StaticImage
                src="../../assets/images/icons/normal_u68.png"
                width={68}
                alt="normal_u68"
              />
            )}
            <Grid item lg={6} md={5} xs={12}>
              <Flip bottom delay={500}>
                <h3 className={classes.creditTitle}>
                  {t("start-here.credit-title-2")}
                </h3>
              </Flip>
              <div className={classes.creditImage}>
                <Fade delay={500}>
                  {i18n.language === "en" ? (
                    <StaticImage
                      src="../../assets/images/picture/Chart_English.png"
                      alt="chart"
                    />
                  ) : (
                    <StaticImage
                      src="../../assets/images/picture/Chart_Indo.png"
                      alt="chart"
                    />
                  )}
                </Fade>
              </div>
              <Flip bottom delay={500}>
                <p className={classes.creditText}>
                  {t("start-here.credit-text-2")}
                </p>
              </Flip>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Layout>
  )
}

export default withTrans(StartHerePage)
